@import "../../common/css-variables.scss";
@import "./css-variables.scss";

.my-expense-tracker {
  padding-bottom: 100px;
}

app-expense,
app-expense-home,
app-reoccurring-expenses-income {
  background: $background-color-dark;
  display: block;
  margin: 0 auto;
  max-width: $app-width;
  width: 100%;
}

.nav-header {
  background: $background-color-dark-secondary;
  padding: 15px;
  position: relative;
  z-index: 2;

  .row {
    display: flex;
    justify-content: space-between;
  }

  .heading-col {
    font-size: 16px;

    .first-heading {
      color: $font-color-primary;
    }

    .description {
      color: $font-color-primary-complimentary;
    }

    .mat-icon {
      cursor: pointer;
      color: white;
      margin-left: -5px;
      margin-right: 10px;
    }
  }
}

.container {
  box-sizing: border-box;
  padding: 10px;
}

.title-bar {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.row--action-buttons {
  min-width: 60px;
  text-align: right;

  a {
    color: $font-color-grey;
    padding: 3px;
  }

  img {
    max-width: 24px;
  }

  .material-icons {
    font-size: 19px;
  }
}

[icon-loaded] {
  + img {
    display: none;
  }
}

[icon-loaded="false"] {
  display: none;

  + img {
    display: inline;
  }
}

h1,
h2 {
  position: relative;
  color: $font-color-primary-dark;
}

h1.underline:before,
h2.underline:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 5%;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(
    to right,
    $font-color-primary-complimentary,
    transparent
  );
}

.input-tag-chip,
.app-tags,
.view-receipt-tag {
  display: flex;
  background: $tag-background-color;
  border-radius: 10px;
  color: $tag-font-color;
  font-size: 13px;
  line-height: 20px;
  margin: 0 6px 2px 0;
  padding: 4px 7px;
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  gap: 2px;
  align-items: center;

  img {
    max-width: 20px;
    border-radius: 20px;
    margin-right: 2px;
    vertical-align: middle;
  }

  &.split-tag {
    margin-left: 2px;
    padding: 0 0 0 7px;
  }
}

.view-receipt-tag {
  background-color: $background-color-primary;
  border: solid 1px $background-color-secondary;
}

.is-blurred {
  filter: blur(5px);
}

// mobile view
@media (max-width: 575.98px) {
  .app-modal-box {
    max-width: 100% !important;

    .mat-mdc-dialog-container {
      background-color: transparent !important;
      padding: 10px;
    }
  }

  .cdk-overlay-pane.mat-mdc-dialog-panel {
    max-width: 100% !important;
  }

  .add-group-modal-box,
  .add-group-tags-modal-box,
  .add-reoccurring-modal-box,
  .add-income-modal-box,
  .add-icon-modal-box,
  .app-modal-box-pick-avatar-modal,
  .app-preview-receipt-modal,
  .add-budget-to-tags-modal-box,
  .add-expense-modal-box,
  .ignore-tags-modal-box,
  .app-modal-split-expense-tags-modal {
    width: 95% !important;
    align-self: center;

    .mat-mdc-dialog-container {
      padding: 0;
      border-radius: 0;

      .mdc-dialog__surface {
        // background: none !important;
        // box-shadow: none !important;
        // display: flex !important;
        // justify-content: center !important;
      }
    }

    &.full-screen-modal-no-padding {
      .mat-mdc-dialog-container {
        padding: 0;
      }
    }
  }

  .app-modal-split-expense-tags-modal {
    border-radius: 12px;
    overflow: hidden;
  }

  .add-expense-modal-box {
    .mat-mdc-dialog-container {
      padding: 0;
      border-radius: 0;
    }
  }

  .app-modal-box-pick-avatar-modal {
    .mat-mdc-dialog-surface {
      justify-content: center !important;
    }
  }
}

.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    background: none !important;
    box-shadow: none !important;
  }
}

body .mat-mdc-expansion-panel-body {
  padding: 0 15px 16px !important;
}

.form-field-wrapper {
  border: solid 1px $border-color;
  padding: 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;

  label {
    color: $form-font-color;
    width: 100%;

    .align-right {
      float: right;
    }

    .previous-purchases {
      padding-right: 7px;
      font-size: 12px;
    }

    + span {
      width: 100%;

      img {
        margin-right: 5px;
        max-width: 25px;
        max-height: 25px;
      }
    }
  }

  .mat-mdc-input-element.mat-mdc-autocomplete-trigger {
    width: 100%;
  }

  input[type="text"],
  input[type="number"],
  app-tag-form-input,
  select {
    font-size: 14px;
    color: #f0f0f0;
    padding: 10px 0;
  }

  .date-picker {
    display: flex;
    width: 100%;

    input {
      flex: 1 1 auto;
    }

    mat-datepicker-toggle {
      max-width: 30px;
      color: #fff;
    }
  }

  &.has-sub-form {
    input {
      border: solid 1px $border-color;
      padding: 4px 5px;
      border-radius: 6px;
      margin: 6px 0;
    }
  }

  .align-center {
    margin: 0 auto;
  }

  .flex-space-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .icon.xl {
    max-height: 50px;
  }
}

.group-tag--list,
.income-list,
.report-by-group,
.budget-list,
.track-list {
  .row {
    box-sizing: border-box;
    background: $expense-row-background-color;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    margin: 15px 0;
    position: relative;
    padding: 2px 10px;

    + .row {
      margin-top: 5px;
    }
  }

  .header {
    background: $admin-header-row-background-color;
    color: $admin-header-row-font-color;
  }

  .row:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 5%;
    width: 100%;
    height: 1px;
  }

  .col-desc {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  .dated {
    color: #f0f0f0;
    font-size: 12px;
    display: block;
  }

  .amount {
    margin-left: auto;
    text-align: right;
    min-width: 60px;
    color: #e7e7e7;
    font-size: 14px;
    flex: 0 0 auto;
  }

  .icon-wrapper {
    margin-right: 15px;

    img {
      max-width: 40px;
      max-height: 40px;
      vertical-align: middle;
      border-radius: 10px 10px 10px 10px;
    }
  }

  &.is-deleted-record {
    display: none;
  }
}

.app-modal-split-expense-tags-modal {
  .modal-header {
    padding-bottom: 30px !important;
  }
}

.full-screen-modal-no-padding {
  position: relative;

  .modal-box--close-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    color: $font-color-white;
  }

  .top-header-bg {
    background: $background-color-primary;
    height: 80px;
  }

  .modal-header {
    height: 100px;
    background: $modal-header-background-color;
    border-radius: 30px 30px 0 0;
    padding: 15px 10px 0;
    text-align: center;

    h1 {
      color: #f0f0f0;
    }
  }

  .modal-heading-hint {
    margin: -5px 0 50px;
    color: white;
  }

  .input-form {
    border-radius: 30px 30px 0 0;
    padding: 20px 10px 0 10px;
    background: $background-color-dark;
    border-radius: 30px;
    margin-top: -40px;
    position: relative;

    .action-buttons {
      text-align: center;
      padding-bottom: 20px;
    }

    .icon {
      position: absolute;
      border: solid 2px $border-dark;
      padding: 10px;
      top: 0;
      left: 50%;
      margin-left: -37.5px;
      margin-top: -30px;
      background: #fff;
      box-sizing: border-box;
      border-radius: 50px;
      height: 65px;
      width: 65px;
      text-align: center;

      img {
        max-width: 100%;
        vertical-align: middle;
      }
    }
  }

  .panel-heading {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-top: 20px;
    padding: 10px;
    font-variant: small-caps;
    align-items: center;

    .col-2 {
      flex: 0 0 320px;
      text-align: right;

      button {
        margin-right: 10px;
      }
    }
  }

  .action-buttons {
    button + button {
      margin-left: 15px;
    }
  }

  @media (max-width: 575.98px) {
    .action-buttons {
      text-align: center;
    }
  }
}

.add-income-modal-box {
  position: relative;
  z-index: 1;
}

.tag-autocomplete {
  .mat-mdc-option {
    height: 35px;
    line-height: 35px;

    &.mat-mdc-active {
      background-color: $background-color-primary;
      color: $font-color-primary;
    }

    .mat-mdc-option-text {
      display: flex;
    }
  }
}

.expense-list-bottom-sheet-container {
  .mat-bottom-sheet-container {
    background: $bottom-sheet-background;
    border-radius: 30px 30px 0 0;
    padding: 0;
    overflow: visible;
  }

  .tracked-by-group {
    max-height: 500px;
    overflow: auto;
  }

  h2 {
    margin: 15px 10px;
    strong {
      color: $bottom-sheet-h2-font-color;
    }
  }

  .zero-record h2 {
    display: none;
  }

  .sheet-icon {
    position: absolute;
    height: 50px;
    width: 50px;
    right: 0;
    top: -45px;
    background: $background-color-dark;
    border-radius: 50px;
    padding: 20px;
    margin-left: -45px;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  .bottom-sheet-container {
    padding-bottom: 50px;
  }
}

.mat-mdc-error {
  font-size: 85%;
}

.pick-date-search-nav {
  display: flex;
}

.month-picker {
  background: $header-button-color;
  border-radius: 30px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
  height: 42px;

  span.month-year {
    padding-left: 10px;
  }

  input {
    width: 55px;
    padding-left: 10px;
    height: 0;
    width: 0;
    position: absolute;
    top: 80%;
    left: 50%;
    outline: none !important;
  }
}

.btn-white[mat-mini-fab] {
  background-color: $white;
}

.mat-button-toggle-checked {
  background-color: $background-color-primary !important;
  color: $font-color-primary !important;
}
mat-button-toggle-group {
  border: none !important;
  mat-pseudo-checkbox {
    display: none !important;
  }
}

.text-align-center {
  text-align: center;
}

.flex-stretch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mat-mdc-chip-remove {
  border: none;

  mat-icon {
    color: $font-color-primary;
    display: block;
    font-size: 18px;
    margin-left: -8px;
    height: 18px;
    width: 18px;
  }
}

.mat-mdc-chip-list-wrapper {
  background: $background-color-dark;
  border-radius: 15px;
}

input.mat-mdc-chip-input {
  color: #f0f0f0;
  min-height: 32px;
  flex: 1 0 100% !important;
  outline: none !important;
}

input.mat-mdc-chip-input::placeholder {
  color: #f0f0f0;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: #101010;
  color: #bcbcbc;

  .mat-mdc-chip-remove {
    opacity: 1;
    background: none;
  }
}

.tag-icon {
  max-height: 24px;
  border-radius: 15px;
  margin-right: 5px;
  vertical-align: middle;
}

.no-records {
  padding: 15px;
}

.zero-record {
  padding: 10px 15px;
}

img[data-loaded="false"] {
  display: none;
}

.show-more {
  text-align: center;
  margin-top: 14px;

  button {
    border: none;
  }
}

.recurring-type {
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 3px 7px;
  color: #fff;
  margin: 4px 0px;
  display: block;
  height: 17px;
  line-height: 17px;
  border-radius: 7px;
}

.recurring-type-1 {
  background: green;
}

.recurring-type-2 {
  background: navy;
}

.recurring-type-3 {
  background: black;
}

.recurring-type-11 {
  background: darkred;
}

.recurring-type-5 {
  background: #0f5304;
}

.align-right {
  text-align: right;
}

.budget-bar-container {
  box-sizing: border-box;
  padding: 0 10px;
  margin: 15px 0;
  color: #f0f0f0;
}

.budget-bar {
  box-sizing: border-box;
  height: 15px;
  border-radius: 10px;
  background: #fafafa;
  margin-top: 5px;
  overflow: hidden;
}

.budget-progress {
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  font-size: 11px;
  font-weight: bold;
  height: 15px;
  position: relative;
  line-height: 16px;
  text-align: right;
  width: 100%;

  .total-percentage {
    right: 0;
    position: absolute;
    margin-right: 50%;
  }
}

.single-tag-percentage {
  border-radius: 10px;
}

.budget-red {
  background: #ff9b9b;
  color: #9c0808;
}

.budget-orange {
  background: #ffa500;
  color: #a77200;
}

.budget-green {
  background: #32cd32;
  color: #118000;
}

.bar-chart-label-color {
  height: 10px;
  width: 10px;
  display: inline-block;
  vertical-align: middle;
}

.total-percentage {
  margin-left: auto;
  margin-right: 5px;
}

.mat-mdc-mini-fab.add-btn-small {
  background-color: $background-color-primary;
  box-shadow: none;
  border-radius: 4px;
  color: $font-color-primary;
  height: 20px;
  line-height: 20px;
  width: 20px;

  .mat-mdc-button-wrapper {
    padding: 0;
  }

  .mat-mdc-icon {
    height: 18px;
    width: 18px;
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 12px;
  }
}

.custom-tag-container {
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: right;

  button {
    background: $custom-tags-button-background;
    border: solid 1px #ccc;
    border-radius: 8px;
    color: $custom-tags-button-fore-color;
    font: 800 11px / 20px $font-family;
    margin-left: 5px;
    padding: 2px 5px 0px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.split-tag-value {
  display: block;
  font-size: 12px;
  background-color: #ddd;
  border-radius: 10px;
  color: #545454;
  padding: 3px 6px;
  white-space: nowrap;

  .material-icons {
    font-size: 14px;
    vertical-align: middle;
  }
}

.tag-original-value {
  font-size: 10px;

  &.normal-value {
    font-size: 14px;
  }
}

.mat-mdc-autocomplete-panel .mat-mdc-option {
  min-height: 40px !important;
  line-height: 40px !important;

  .mdc-list-item__primary-text {
    color: #f0f0f0 !important;
    width: 100%;
  }
}
.tag-autocomplete {
  .mat-mdc-autocomplete-panel .mat-mdc-option {
    min-height: 30px !important;
    line-height: 30px !important;
  }
  .mdc-list-item__primary-text {
    display: flex;
  }
}

.mat-mdc-autocomplete-panel .mat-mdc-option.mat-mdc-active {
  background-color: #5c5c5c !important;
  color: #f0f0f0 !important;
}

.mat-mdc-autocomplete-panel {
  background: linear-gradient(135deg, #6c539b 0%, #58525f 100%) !important;
  padding: 10px 0 0 0 !important;
  border-radius: 10px !important;

  .mat-mdc-option {
    font-size: 13px;
  }

  .icon {
    max-width: 30px;
    vertical-align: middle;
    margin-right: 10px;
  }

  .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-active):not(:hover) {
    background: #2c2c2c !important;
  }

  .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-active):not(:hover):not(
      .mat-mdc-option-disabled
    ) {
    color: #f0f0f0 !important;
  }
}

.month-separator {
  padding: 14px;
  font-size: 10px 12px;
  font-variant: small-caps;
  background: $month-separator-background;
  color: $month-separator-font-color;
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 10px;
  width: 98%;
  margin: 10px auto 0;
  display: flex;
  justify-content: space-between;
}

input,
::placeholder,
input::placeholder {
  color: #f0f0f0 !important;
  opacity: 1 !important;
}

.blur-background {
  background-color: #484848;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.4;
}

.mat-mdc-dialog-container {
  background-color: transparent !important;
  box-shadow: none;
  position: relative;
}

.mat-datepicker-content {
  background-color: transparent !important;
  box-shadow: none;
  margin-top: 10px;

  .mat-calendar {
    background: $date-picker-background-color;
    border-radius: 30px;
    box-shadow: $date-picker-container-shadow;
  }
}

app-mobile-nav-bar {
  bottom: 10px;
  left: 10px;
  position: fixed;
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tag-list {
  align-items: center;
  color: $input-font-color-light;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin-right: auto;

  > p {
    margin: 0 0 4px 0;
  }
}

[aria-expanded="true"] {
  .vendor-purchasing {
    background: #282560 !important;
    margin-bottom: 0;
    border-radius: 10px 10px 0 0;
    transition: all 0.2s ease-out;
  }

  mat-icon {
    transform: rotate(180deg);
  }
}

.vendor-expenses-list {
  background: $vendor-expense-list-container;
  border-radius: 0 0 15px 15px;
  box-shadow:
    1.4px 12.2px 4.5px -19px rgb(0 0 0 / 4%),
    5px 43px 36px -19px rgb(0 0 0 / 7%);

  .expense-row {
    background: transparent !important;
    margin: 0 !important;
    box-shadow: none !important;
  }
}

.vendor-expenses-list,
.expenses-list {
  box-sizing: border-box;
  width: 98%;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.7s;
}

.mat-mdc-input-element {
  color: $input-font-color-light !important;
  background-color: transparent !important;
  border: none !important;
  outline: none;
  width: 100%;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 28px !important;
}

.cdk-overlay-dark-backdrop {
  -webkit-backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

input:-webkit-autofill,
input:autofill {
  box-shadow: 0 0 0px 1000px #92a5e5 inset; /* Background color */
  -webkit-text-fill-color: #000; /* Text color */
  text-fill-color: #000; /* Text color */
  border: 0px; /* Border color */
}

.mat-mdc-standard-chip {
  height: auto !important;
  .mdc-evolution-chip__text-label {
    color: white !important;
  }
  .mdc-evolution-chip__action--trailing {
    padding-right: 0 !important;
  }
  .mdc-evolution-chip__action--primary {
    padding-left: 0 !important;
  }
}

.mdc-checkbox__native-control {
  display: none;
}

.cdk-global-overlay-wrapper {
  justify-content: center !important;
}
