$app-width: 575px;

$background-color-dark: #3a3775;
$background-color-dark-secondary: #35327c;
$background-color-light: #d5e3ff;

$font-family:
  Source Sans Pro,
  Arial,
  Helvetica,
  sans-serif;

$input-font-color-dark: $background-color-dark;
$input-font-color-light: $background-color-light;
$login-container-background: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);

$border-color: #ccc;
$border-dark: #0039a5;

$app-width: 575px;
$avatar-bg: linear-gradient(180deg, #29254f 0%, #4e1593 100%);

$spinner-color: #0039a5;
$top-expense-circle-background: $background-color-dark;
$background-color-secondary: #0058ff;

$background-color-white: #fff;
$background-color-primary: #0039a5;
$background-color-secondary: #0058ff;
$background-color-light: #c7efff;
$background-color-modal: rgba(0, 0, 0, 0.65);

$font-family:
  Source Sans Pro,
  Arial,
  Helvetica,
  sans-serif;
$font-color-primary: #c7efff;
$font-color-primary-complimentary: #a7b6d1;
$font-color-primary-dark: #82aaf5;
$font-color-dark-grey: #333;
$font-color-grey: #666;
$font-color-black: #222;
$font-color-white: #fff;
$font-color-disabled: #ccc;
