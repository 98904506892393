$white: white;
$font-color-dark: #ffd740;

$background-color-dark: #3a3775;
$background-color-dark-secondary: #35327c;
$background-color-light: #d5e3ff;

$input-font-color-dark: $background-color-dark;
$input-font-color-light: $background-color-light;

$background-color-top-tiles: #5450b1;
$background-color-top-tiles2: #521e8a;
$font-color-top-tiles: #ffd740;
$tile-box-shaddow1: #433f87;
$tile-box-shaddow2: #312f63;

$border-color: #ccc;

$header-button-color: $background-color-light;
$date-picker-background-color: linear-gradient(
  135deg,
  #f3ebff 0%,
  #cea5ff 100%
) !important;
$date-picker-container-shadow:
  20px 20px 60px #312f63,
  -20px -20px 60px #433f87;

$nav-menu-background1: #9046f5;
$nav-menu-background2: #7e49f5;
$nav-menu-background3: #5048ec;

$tag-background-color: linear-gradient(45deg, #524e95 0%, #504da5 100%);
$tag-font-color: #bcbcbc;

$split-value-bg-color: #002770;

$modal-header-background-color: #6c539b;

$month-separator-gradient-color-1: #282560;
$month-separator-gradient-color-2: #2c2a4e;
$month-separator-background: linear-gradient(
  62deg,
  $month-separator-gradient-color-1 0%,
  $month-separator-gradient-color-2 100%
);
$month-separator-font-color: $font-color-dark;

$expense-row-background-color: #2c2a4f3b;
$expense-row-shadow: #2a275a;
$expense-user-initials-background: #871616;

$yearly-chart-filter-tags-button-background-color: $header-button-color;
$yearly-chart-filter-tags-button-color: $background-color-dark;
$yearly-chart-filter-tags-modal-background: $modal-header-background-color;

$vendor-expense-list-container: linear-gradient(
  180deg,
  #282560 0%,
  #6800bda1 100%
);

$search-results-background: $modal-header-background-color;

$custom-tags-button-background: linear-gradient(
  135deg,
  #fdeb71 10%,
  #f8d800 100%
);
$custom-tags-button-fore-color: $input-font-color-dark;

$bottom-sheet-background: linear-gradient(to bottom right, #813091, #383375);
$bottom-sheet-h2-font-color: $font-color-dark;

$split-tag-modal-total-color: $font-color-dark;
$split-tag-modal-button-background: #9046f5;

$price-helper-button-background-color: linear-gradient(
  135deg,
  #71fd78 10%,
  #23b313 100%
);
$price-helper-button-font-color: $input-font-color-dark;

$form-font-color: #e9e9e9;

$admin-header-row-background-color: linear-gradient(
  62deg,
  #282560 0%,
  #2c2a4e 100%
);
$admin-header-row-font-color: $background-color-light;

$background-color-white: #fff;
$background-color-primary: #0039a5;
$background-color-secondary: #0058ff;
$background-color-light: #c7efff;
$background-color-modal: rgba(0, 0, 0, 0.65);

$font-family:
  Source Sans Pro,
  Arial,
  Helvetica,
  sans-serif;
$font-color-primary: #c7efff;
$font-color-primary-complimentary: #a7b6d1;
$font-color-primary-dark: #82aaf5;
$font-color-dark-grey: #333;
$font-color-grey: #666;
$font-color-black: #222;
$font-color-white: #fff;
$font-color-disabled: #ccc;
