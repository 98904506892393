@use "@angular/material" as mat;
@include mat.core();
@import "app/common/css-variables.scss";

$my-typography: mat.define-typography-config(
  $font-family: $font-family,
);
@include mat.typography-hierarchy($my-typography);

:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --font-size: 16px;
  /* Add more variables as needed */
}

html {
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

html,
body,
app-root,
app-shell {
  height: calc(100% - 30px);
  display: block;
  top: 0 !important; // hack to make app stay on top when mat modal box opens
}

body {
  background: url(assets/mini-apps/expense-tracker/images/background.svg),
    $background-color-dark;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  height: 100%;
  overflow: auto;
  position: relative;

  .hide {
    display: none !important;
  }
}

input,
textarea {
  caret-color: yellow !important;
}

input::selection,
textarea::selection {
  background: #ffeb3b !important; /* Light yellow */
  color: black !important; /* Black text */
}

.app-screen-size--large,
.app-screen-size--x-large,
.app-screen-size--medium {
  height: 100%;
}

.app-screen-size--x-small,
.app-screen-size--small {
  height: 100%;
  .login-container {
    box-sizing: border-box;
    // padding-top: 100px;
  }
}

.login-container {
  .mat-mdc-input-element {
    color: $input-font-color-dark !important;
  }

  .wrapper {
    background: $login-container-background !important;
    transition: all ease-in-out 0.5s;
  }
}
