@import "./css-variables.scss";

.mat-mdc-select-panel {
  background-color: $dropdown-background !important;
  border-radius: 10px !important;

  .mat-mdc-option.mdc-list-item {
    min-height: 32px !important;
    color: $dropdown-color !important;
  }
}

.mat-mdc-dialog-container {
  width: $app-width !important;
}

.habit-tracker-modal {
  .mat-mdc-dialog-component-host > div {
    border-radius: 10px !important;
    background-color: $modal-background !important;
    margin: 15px;
    overflow: hidden;
  }
  .modal-box--close-button {
    position: absolute;
    right: 15px;
    top: 20px;
    z-index: 1;
    color: $font-color-white;
    background: none !important;
    border: none !important;
  }
  .modal-header {
    padding: 10px;
    background-color: $modal-header;
    color: $font-color-primary;
    margin-bottom: 10px;
  }
  .modal-body {
    padding: 10px;
  }
}

.habit-tracker-app {
  background-color: $habit-tracker-app-background !important;

  app-login {
    h1 {
      margin: 10px auto 20px !important;
      color: $font-color-dark-complimentary !important;
    }
    .wrapper {
      background: $login-background !important;
      transition: all ease-in-out 0.5s;
    }

    .button-primary {
      background: $primary-button-background !important;
      color: $primary-button-color !important;
    }

    .mat-mdc-form-field .mdc-text-field__input {
      color: $font-color-dark-complimentary !important;
    }
  }

  input:-webkit-autofill,
  input:autofill {
    box-shadow: 0 0 0px 1000px #929a1e inset; /* Background color */
    -webkit-text-fill-color: #000; /* Text color */
    text-fill-color: #000; /* Text color */
    border: 0px; /* Border color */
  }

  input::placeholder,
  textarea::placeholder {
    color: $placeholder-text-color !important; /* Change this to the color you want */
  }

  // dropdown
  .mat-mdc-select-value {
    color: $font-color-primary;
    text-transform: capitalize;
  }

  // auto complete
  .mat-mdc-autocomplete-panel,
  .mat-mdc-select-panel {
    background: $auto-complete-background !important;
    border-radius: 10px !important;
    padding: 0 !important;

    .mdc-list-item__primary-text {
      color: $auto-complete-color !important;
      font-size: 14px !important;
    }
  }

  // date picker
  .mat-datepicker-content .mat-calendar {
    border-radius: 30px;
    background: $date-picker-background !important;
    box-shadow: $date-picker-shadow !important;
  }
  .mat-calendar-table-header th,
  .mat-calendar-body-label,
  .mat-calendar-body-cell-content,
  .mat-mdc-button:not(:disabled) {
    color: $date-picker-color !important;
  }
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    border-color: $date-picker-color !important;
  }
  .mat-datepicker-content
    .mat-calendar-previous-button:not(.mat-mdc-button-disabled),
  .mat-datepicker-content
    .mat-calendar-next-button:not(.mat-mdc-button-disabled) {
    color: $date-picker-color !important;
  }

  // form fields
  .form-field-wrapper {
    border: solid 1px $input-border-color;
    .mat-mdc-form-field-error {
      color: $error-color;
    }
  }

  // h1
  h1 {
    color: $font-color-dark !important;
    margin: 0 !important;
  }

  // button
  .primary-button {
    background-color: $primary-button-background;
    color: $primary-button-color;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .secondary-button {
    background-color: $secondary-button-background;
    color: $secondary-button-color;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .add-avatar-modal-box {
    background: $background !important;

    .selected {
      background: $avatar-selected-background-color;
    }
  }

  // confirmation modal
  .confirmation-modal {
    background-color: $confirmation-modal-background !important;
    color: $confirmation-modal-color !important;
    margin: 0 20px;
  }
}
