$habit-tracker-app-background: #422b2b;
$login-background: linear-gradient(135deg, #9da33f 0%, #899100 100%);

$font-color-primary: #c7efff;
$font-color-primary-complimentary: #a7b6d1;
$font-color-primary-dark: #82aaf5;
$font-color-white: #fcfcfc;
$font-color-dark: #ffd740;
$font-color-dark-complimentary: #422b2b;
$font-color-grey: #a7b6d1;
$font-color-black: #222222;

$nav-menu-background1: #422b2b;
$nav-menu-background2: #5e3e3e;
$nav-menu-background3: #7a5151;

$nav-menu-shadow1: #432b2c;
$nav-menu-shadow2: #482c2b;

$dropdown-background: #7e49f5;
$dropdown-color: #fff;

$app-width: 575px;

$modal-background: #422b2bad;
$modal-header: #833a13;

// auto complete date picker, list items
$background: linear-gradient(135deg, #422b2b 0%, #5e3e3e 100%);
$background-shadow:
  10px 10px 20px #7a515178,
  -10px -10px 20px #7a515175;

$date-picker-shadow: $background-shadow;
$date-picker-background: $background;
$date-picker-color: $font-color-dark;

$auto-complete-background: $modal-header;
$auto-complete-color: $font-color-dark;

// form fields
$input-border-color: #974519;

// button
$primary-button-background: #974519;
$primary-button-color: $font-color-dark;

$secondary-button-background: #5e3e3e;
$secondary-button-color: $font-color-white;

$placeholder-text-color: #727272;
$error-color: #ff587a;

$avatar-selected-background-color: $modal-background;

$confirmation-modal-background: $primary-button-background;
$confirmation-modal-color: $font-color-white;

$quote-font-family:
  "Poiret One",
  Source Sans Pro,
  Arial,
  Helvetica,
  sans-serif;

$quote-underline-color: #ffffff30;
